.row .col {
    min-height: 0;
}

.ma-card-col-2 .ma-card-col {
    @extend .m6;
}

.ma-card-col-3 .ma-card-col {
    @extend .m4;
}

.ma-card-col-4 .ma-card-col {
    @extend .m3;
}

.ma-region.card .row {
    margin-bottom: 0;
}
