/*!
 * material-apex - Material Design Theme for Oracle APEX 5
 * @author Vincent Morneau
 * @version v0.9.5
 * @link 
 * @license MIT
 */
@charset "UTF-8";

// Required files for overwrites
// @import "components/prefixer";
@import "components/mixins";
@import "components/color";

// Custom APEX variables
@import "apex_variables";
@import "apex_mixins";

// Original materialized CSS files
@import "materialize";

// Custom APEX styles
@import "apex_buttons";
@import "apex_global";
@import "apex_cards";
@import "apex_code";
@import "apex_collapsible";
@import "apex_collections";
@import "apex_datepicker";
@import "apex_demo";
@import "apex_dropdown";
@import "apex_forms";
@import "apex_grid";
@import "apex_icons";
@import "apex_ir";
@import "apex_materialbox";
@import "apex_media";
@import "apex_modal";
@import "apex_nav";
@import "apex_pagination";
@import "apex_preloader";
@import "apex_promo";
@import "apex_sidenav";
@import "apex_table";
@import "apex_tabs";
@import "apex_tabular_form";
@import "apex_themeroller";
@import "apex_toasts";
@import "apex_typography";
@import "apex_spacers";
