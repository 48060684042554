/*
Global
*/
.ma-field-container {
    position: relative;
}

/*
Colors
*/
.ma-colored-field {
    label {
        // we are enforcing this color so it should overwrite the :focus value
        color: inherit!important;
    }

    input {
        // we are enforcing this color so it should overwrite the :focus value
        border-color: inherit!important;
        box-shadow: inherit!important;
    }
}

/*
Date Pickers
*/
// This is a fix for the wrong margin and padding in default.date.scss
.picker__select--month,
.picker__select--year {
  	padding: 0;
}

@media (min-width: 24.5em) {
	.picker__select--month,
	.picker__select--year {
		margin-top: 0;
	}
}

/*
Inputs
*/
.input-field {
    margin-top: 1.5rem;

	label {
		left:0;

		&.active {
    		transform: translateY(-190%);
		}
	}
}

.item-no-label > label {
	display: none!important;
}

.item-image-responsive img {
	@extend .responsive-img;
}

.item-image-circle img {
	@extend .circle;
}

.item-fade-in img {
    @extend .fade-in;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
    display: block;
}

:focus {
	outline:none;
}

/*
Autocomplete
*/
div.ac_results {
    border: none;
    overflow: visible;;

	ul {
		@extend .autocomplete-content;
		@extend .dropdown-content;

        li {
            font-size: 16px;
            color: $primary-color;
            display: block;
            line-height: 22px;
            padding: 14px 16px;

            &.ac_odd {
                background-color: initial;
            }

            &.ac_over {
                background-color: #eee;
            }

            strong {
                font-weight: 500;
                color: #444;
            }
        }
	}
}

/* Popup LOV */
body.uPopUpLOV div.uActionBar {
    height: initial;

    input[type=text] {
    	width: 100%;
    }
}

.ma-popuplov {
    label {
        transform: translateY(-190%);
        font-size: 0.8rem;
    }

    .prefix ~ fieldset {
        margin-left: 3rem;
    }
}

/* Validation */
span.ma-error-message {
    color: #EF5350;
    font-size: 0.8rem;
    position: absolute;
    top: 0;
}

.input-field {
    br {
        display: none;
    }

    span.ma-error-message {
        top: 3.5rem;
    }
}

/* display only */
label.active.display-only {
	margin-bottom: 10px;
	display: block;
}

.input-field .prefix ~ .display_only {
   margin-left: 3rem;
}

.display-only-container {
    margin-top: 3rem;

    &.item-no-label {
        margin-top: 0;
    }
}

.input-field.display-only-container .prefix {
    font-size: 24px;
}

/* Switches */
.switch {
    margin-top: -0.8rem;
}

.switch-fix {
    top: -0.8rem;
}

.input-field .switch+label {
    margin-top: -1.8rem;
}

/* Ranges */
.input-field .range-field+label {
    top: -.8rem;
}
