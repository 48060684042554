.card-title {
	z-index: 1;
}

.panel-close {
    cursor: pointer;
}

.ma-card-horizontal .card {
    @extend .horizontal;
}

.ma-sticky-action .card {
    @extend .sticky-action;
}
