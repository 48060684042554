body {
	background: $background-color;
}

form p {
    text-align: inherit;
}

ul {
	margin: 0;

	&.table-of-contents {
		margin-top: 0;
		padding-top: 48px;
	}
}

.main-container .ma-main-container {
	@extend .container;
}

i {
	&.left-l {
		float: left;
		margin-right: 0;

		@media #{$large-and-up} {
			margin-right: 15px;
		}
	}

	&.right-l {
		float: right;
		margin-left: 0;

		@media #{$large-and-up} {
			margin-left: 15px;
		}
	}
}

#menu-trigger {
	display:block!important;
	padding-left: 15px;
}

.materialbox-caption {
	top:0;
}

/*
Sticky Footer
*/
form {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

main {
	flex: 1 0 auto;
}

/*
Alignments
*/
.ma-region {
    // title
    &.ma-region-title-left .ma-region-title {
    	text-align: left;
    }
    &.ma-region-title-center .ma-region-title {
        text-align: center;
    }
    &.ma-region-title-right .ma-region-title {
    	text-align: right;
    }

    // body
    &.ma-region-body-left .ma-region-body {
    	text-align: left;
    }
    &.ma-region-body-center .ma-region-body {
        text-align: center;
    }
    &.ma-region-body-right .ma-region-body {
    	text-align: right;
    }

    // buttons
    &.ma-region-buttons-left .ma-region-buttons {
    	text-align: left;
    }
    &.ma-region-buttons-center .ma-region-buttons {
        text-align: center;
    }
    &.ma-region-buttons-right .ma-region-buttons {
    	text-align: right;
    }
}

/*
Badges
*/
span.badge.new:after {
    content: "";
}

/* Notifications */
.htmldbStdErr a {
    color: inherit;
}

/* Parallax */
.parallax-container {
    height: initial;
    min-height: 400px;
}

/* Blockquote */
blockquote {
    padding: 1rem;
}
