body.body-modal {
    background-color: $modal-background-color;
}

.modal {
    display: block;
    width: 100%;
    max-height: 100%;
    box-shadow:none;
    background-color: $modal-background-color;

    &.modal-fixed-footer {
      height: 100%;

        .modal-footer {
            text-align:right;

            a {
                float: none;
            }
        }
    }

    &.bottom-sheet {
        top: auto;
        bottom: 0;
        left: 0;
        width: 100%;
        display: block;
    }

    .modal-content {
        padding-top: 0;
    }

    .modal-footer {
        background-color: $modal-background-footer;
    }
}

.region.modal {
    &.bottom-sheet {
          top: auto;
          bottom: -100%;
          width: 100%;

          .modal-footer {
                display:none;
          }
    }

    .modal-content {
        padding-top: 24px;
    }
}

body {
    .ui-front, .ui-widget-overlay {
        z-index: 999;
    }

    .ui-dialog {
        border-width: 0;
        border-radius: 2px;

        position: absolute;
        top: 0;
        left: 0;
        padding: 0;
        outline: 0;
        box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.22), 0 25px 55px 0 rgba(0, 0, 0, 0.21);
        box-sizing: content-box;

        background-color: $modal-background-color;
        max-height: 100%;
        overflow-y: auto;
        z-index: 1000;
        background-clip: padding-box;
        transform: translate(0);
        will-change: top, opacity;

        .ui-dialog-titlebar {
            padding: 24px 24px 0px 24px;
            background-color: $modal-background-header;
            background-image: none;
            border: none;
        }

        .ui-dialog-title {
            @extend h4;
            margin-top: 0;
            width: 80%;
        }

        .ui-dialog-titlebar-close {
            display:none;
        }

        .ui-dialog-content {
            position: relative;
            border: 0;
            padding: 0;
            background: 0 0;
            overflow: auto;

            iframe {
                border: 0;
                width: 100%;
                background-color: $modal-background-color;
            }
        }

        .ui-widget-overlay {
            position: fixed;
            z-index: 999;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            height: 115%;
            width: 100%;
            background: #000;
            will-change: opacity;
            display: block;
            opacity: 0.5;
        }
    }
}

@media #{$small-and-down} {
    body .ui-dialog {
        width: 90%!important;
    }
}
