i {
	&.sm-md {
		font-size: 3rem;
	}

	&.xlarge {
		font-size: 8rem;
	}

	&.promo {
		color: $primary-color;
	}
}