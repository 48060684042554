// temporary fix for search bar
.nav-wrapper, .nav-wrapper form, .nav-wrapper form .input-field{ height: 100%; }
// end temporary fix for search bar

.page-navbar-fixed .top-nav-wrapper {
    @extend .navbar-fixed;
}

nav.top-nav {
    z-index: 998;
}

nav ul a {
    display: inline-block;
}

nav .nav-wrapper i {
	font-size: 2rem!important;
}

@media #{$medium-and-up} {
  	nav .nav-wrapper i {
		font-size: 2.7rem!important;
	}
}

.drag-target {
	top:$navbar-height-mobile;
}

@media #{$medium-and-up} {
  	.drag-target {
		top:$navbar-height;
	}

	// [role="tabpanel"] {
	// 	padding-top:$navbar-height;
	// }
}

.page-center-logo .brand-logo {
	@extend .center;
}

.navbar-list li {
    height: $navbar-height-mobile;
    line-height: $navbar-height-mobile;

    i {
        height: $navbar-height-mobile;
        line-height: $navbar-height-mobile;
    }
}

@media #{$medium-and-up} {
	.navbar-list li {
	    height: $navbar-height;
	    line-height: $navbar-height;

	    i {
		    height: $navbar-height;
		    line-height: $navbar-height;
		}
	}
}

/* Scrollspy */
.apex-rds {
    @extend .table-of-contents;
}

.apex-rds-selected a {
    font-weight: 500;
    padding-left: 18px;
    border-left: 2px solid $secondary-color;
}
