$sidenav-height: 44px;

.side-nav {
    z-index: 1002;
    height: 100%;
    width: $sidenav-width;

	// Right Align
	&.right-aligned {
		right: initial;
		left: initial;
	}

	li {
		line-height: $sidenav-height;

		i {
			margin-right: 1rem;
		  	font-size: 1.5rem;
	  	  	float: left;
		  	line-height: $sidenav-height;
		  	height: $sidenav-height;
		}

		a {
			line-height: $sidenav-height;
			height: $sidenav-height;
		}
	}

	a {
		padding: 0 15px;
	}

	.collapsible {
		border: none;
		box-shadow: none;

        .collapsible-header {
        	min-height: inherit;
        }

        .collapsible-body {
            background-color: $sidenav-bg-color;
        }
	}
}

#sidenav-overlay {
  	z-index: 998;
}

.drag-target {
    z-index: 999;
}

/*
Sidebar Fixed
*/
.side-nav-fixed {
    .side-nav {
        @extend .fixed;
        z-index: 999!important;
    }

	@media #{$large-and-up} {
        #menu-trigger {
            display: none!important;
        }

		.side-nav {
    		left:0!important;
            transform: translateX(0)!important;
		}

    	header, main, footer {
        	padding-left: $sidenav-width;
      	}

     	.top-nav {
            width: calc(100% - #{$sidenav-width});
        }

        .container, .ma-main-container {
            width: 85%;
        }
	}

  	@media #{$medium-and-down} {
        #menu-trigger {
            display: block!important;
        }

    	header, main, footer {
      		padding-left: 0;
    	}

	  	.top-nav {
		    width: 100%;
		}
  	}
}
