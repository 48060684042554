/* Region title size */
.h1 > .ma-region-title, .input-field.h1 {
	font-size: $h1-fontsize;
    font-weight: 300;
}

.h2 > .ma-region-title, .input-field.h2 {
	font-size: $h2-fontsize;
    font-weight: 300;
}

.h3 > .ma-region-title, .input-field.h3 {
	font-size: $h3-fontsize;
    font-weight: 300;
}

.h4 > .ma-region-title, .input-field.h4 {
	font-size: $h4-fontsize;
    font-weight: 300;
}

.h5 > .ma-region-title, .input-field.h5 {
	font-size: $h5-fontsize;
    font-weight: 300;
}

.h6 > .ma-region-title, .input-field.h6 {
	font-size: $h6-fontsize;
    font-weight: 300;
}

.ma-region.hide-title > .ma-region-title, .ma-region.hide-title .card-content > .ma-region-title {
    @include hide();
}

.big-label {
	font-size: 1.5rem;
}

.text-nowrap {
    white-space: nowrap;
}

/* Overrides */
.ma-region-title-amber-text .ma-region-title {
    @extend .amber-text;
}

.ma-region-title-blue-grey-text .ma-region-title {
    @extend .blue-grey-text;
}

.ma-region-title-blue-text .ma-region-title {
    @extend .blue-text;
}

.ma-region-title-brown-text .ma-region-title {
    @extend .brown-text;
}

.ma-region-title-cyan-text .ma-region-title {
    @extend .cyan-text;
}

.ma-region-title-deep-orange-text .ma-region-title {
    @extend .deep-orange-text;
}

.ma-region-title-deep-purple-text .ma-region-title {
    @extend .deep-purple-text;
}

.ma-region-title-green-text .ma-region-title {
    @extend .green-text;
}

.ma-region-title-grey-text .ma-region-title {
    @extend .grey-text;
}

.ma-region-title-indigo-text .ma-region-title {
    @extend .indigo-text;
}

.ma-region-title-light-blue-text .ma-region-title {
    @extend .light-blue-text;
}

.ma-region-title-light-green-text .ma-region-title {
    @extend .light-green-text;
}

.ma-region-title-lime-text .ma-region-title {
    @extend .lime-text;
}

.ma-region-title-orange-text .ma-region-title {
    @extend .orange-text;
}

.ma-region-title-pink-text .ma-region-title {
    @extend .pink-text;
}

.ma-region-title-purple-text .ma-region-title {
    @extend .purple-text;
}

.ma-region-title-red-text .ma-region-title {
    @extend .red-text;
}

.ma-region-title-teal-text .ma-region-title {
    @extend .teal-text;
}

.ma-region-title-white-text .ma-region-title {
    @extend .white-text;
}

.ma-region-title-yellow-text .ma-region-title {
    @extend .yellow-text;
}

/* Truncate */
.truncate > .ma-region-body {
    @extend .truncate;
}
