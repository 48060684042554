span.lov, td.lov, fieldset.lov  {
	white-space: nowrap;
    width: 100%;
    padding: 0;

	input[type=text]:disabled {
		color: inherit;
		border-bottom: 1px solid #9e9e9e;
	}

	a {
	    font-family: 'Material Icons';
	    font-weight: normal;
	    font-style: normal;
	    font-size: 24px;
	    line-height: 1;
	    letter-spacing: normal;
	    text-transform: none;
	    display: inline-block;
	    word-wrap: normal;
	    -webkit-font-feature-settings: 'liga';
	    -webkit-font-smoothing: antialiased;

	    top: 7px;
	    position: relative;

	    &:before {
	    	content:"\e316";
	    }
	}
}
