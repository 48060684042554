.ui-widget {
	font-family: "Lucida Grande", "Lucida Sans Unicode", Helvetica, Arial, Verdana, sans-serif;
	background: $primary-color;
	border: none;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
}

.ui-datepicker {
	padding: 0;
	@extend .card-panel;
}

.ui-datepicker-header {
	border: none;
	background: transparent;
	font-weight: normal;
	font-size: 15px;
}

.ui-datepicker-header .ui-state-hover {
	background: transparent;
	border-color: transparent;
	cursor: pointer;
	border-radius: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
}

.ui-datepicker .ui-datepicker-title {
	margin-top: .4em;
	margin-bottom: .3em;
	color: #e9f0f4;
}

.ui-datepicker .ui-datepicker-prev-hover,
.ui-datepicker .ui-datepicker-next-hover,
.ui-datepicker .ui-datepicker-next,
.ui-datepicker .ui-datepicker-prev {
	top: .9em;
	border:none;
}

.ui-datepicker .ui-datepicker-prev-hover {
	left: 2px;
}

.ui-datepicker .ui-datepicker-next-hover {
	right: 2px;
}

.ui-datepicker .ui-datepicker-next span,
.ui-datepicker .ui-datepicker-prev span {
	background-image: url(../img/ui-icons_ffffff_256x240.png);
	background-position: -32px 0;
	margin-top: 0;
	top: 0;
	font-weight: normal;
}

.ui-datepicker .ui-datepicker-prev span {
	background-position: -96px 0;
}

.ui-datepicker table {
	margin: 0;
}

.ui-datepicker th {
	padding: 1em 0;
	color: #fff;
	font-size: 13px;
	font-weight: normal;
	border: none;
}

.ui-datepicker td {
	background: #fff;
	border: none;
	padding: 0;
}

td .ui-state-default {
	background: transparent;
	border: none;
	text-align: center;
	padding: .5em;
	margin: 0;
	font-weight: normal;
	color: #efefef;
	font-size: 16px;
}

.ui-state-disabled  {
	opacity: 1;
}

.ui-state-disabled .ui-state-default {
	color: #fba49e;
}

td .ui-state-active,
td .ui-state-hover {
	background: $primary-color;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
	background: none;
}

.ui-datepicker select {
	display: inline-block;
}

.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
	width: 40%;
	margin: 5px;
	color: #222;
}

.ui-datepicker select option {
	color: #222;
}

.ui-datepicker-trigger:focus {
    background-color: initial;
}

// .ui-datepicker-trigger ~ label {
//     margin-left: 3rem;
// }

/* Tabular Form */
input.hasDatepicker {
    width: calc(100% - 32px);
}
