// Icons
.icon-float-left i {
	@extend .left;
}

.icon-float-right i {
	@extend .right;
}

// FAB (Fixed Action Button)
.fixed-action-btn {
    bottom: 45px;
    // left: initial;
    // right: initial;
    z-index: 997;

    &.fab-left {
        left: 25px;
    }

    &.fab-right {
        right: 25px;
    }

    &.fab-absolute {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    &.horizontal ul li {
        margin: 5px 0 0 0;
    }

    ul {
        bottom: 80px;

        a.btn-floating {
            opacity: 0;
            margin: 5px 10px;
        }
    }
}

.fab-relative {
    position: relative;
}
