.video-container video {
	@extend .responsive-video;
}

.valign-wrapper {
    margin-bottom:0;

    .row {
        @extend .valign-wrapper;
    }
}
