/* ==========================================================================
Material APEX Variables
========================================================================== */

/**
 * Table of Contents:
 *
 *  1.  Colors
 *  2.  Badges
 *  3.  Buttons
 *  4.  Cards
 *  5.  Collapsible
 *  6.  Chips
 *  7.  Date Picker
 *  8.  Dropdown
 *  10. Forms
 *  11. Global
 *  12. Grid
 *  13. Navigation Bar
 *  14. Side Navigation
 *  15. Photo Slider
 *  16. Spinners | Loaders
 *  17. Tabs
 *  18. Tables
 *  19. Toasts
 *  20. Typography
 *  21. Footer
 *  22. Flow Text
 *  23. Collections
 *  24. Progress Bar
 *  25. Interactive Reports
 *  26. Modals
 */

/*
    {
        "translate": false,
        "groups": [{
            "name": "Colors",
            "common": true,
            "sequence": 1
        }, {
            "name": "Badges",
            "common": true,
            "sequence": 2
        }, {
            "name": "Buttons",
            "common": true,
            "sequence": 3
        }, {
            "name": "Cards",
            "common": true,
            "sequence": 4
        }, {
            "name": "Collapsible",
            "common": false,
            "sequence": 5
        }, {
            "name": "Chips",
            "common": false,
            "sequence": 6
        }, {
            "name": "Date Picker",
            "common": false,
            "sequence": 7
        }, {
            "name": "Dropdown",
            "common": false,
            "sequence": 8
        }, {
            "name": "Forms",
            "common": true,
            "sequence": 10
        }, {
            "name": "Global",
            "common": true,
            "sequence": 11
        }, {
            "name": "Grid",
            "common": false,
            "sequence": 12
        }, {
            "name": "Navigation Bar",
            "common": false,
            "sequence": 13
        }, {
            "name": "Side Navigation",
            "common": false,
            "sequence": 14
        }, {
            "name": "Photo Slider",
            "common": false,
            "sequence": 15
        }, {
            "name": "Spinners | Loaders",
            "common": false,
            "sequence": 16
        }, {
            "name": "Tabs",
            "common": false,
            "sequence": 17
        }, {
            "name": "Tables",
            "common": true,
            "sequence": 18
        }, {
            "name": "Toasts",
            "common": false,
            "sequence": 19
        }, {
            "name": "Typography",
            "common": false,
            "sequence": 20
        }, {
            "name": "Footer",
            "common": false,
            "sequence": 21
        }, {
            "name": "Flow Text",
            "common": false,
            "sequence": 22
        }, {
            "name": "Collections",
            "common": false,
            "sequence": 23
        }, {
            "name": "Progress Bar",
            "common": false,
            "sequence": 24
        }, {
            "name": "Interactive Reports",
            "common": false,
            "sequence": 25
        }, {
            "name": "Modals",
            "common": false,
            "sequence": 26
        }]
    }
*/

/* 1. Colors
   ========================================================================== */
/*
{
   "var": "@background-color",
   "name": "Body Background",
   "type": "color",
   "group": "Colors"
}
*/
$background-color: #ececec;
/*
{
    "var": "@primary-color",
    "name": "Primary",
    "type": "color",
    "group": "Colors"
}
*/
$primary-color: #00bcd4;
$primary-color-light: lighten($primary-color, 15%) !default;
$primary-color-dark: darken($primary-color, 15%) !default;
/*
{
    "var": "@secondary-color",
    "name": "Secondary",
    "type": "color",
    "group": "Colors"
}
*/
$secondary-color: #EF5350;
/*
{
    "var": "@success-color",
    "name": "Success",
    "type": "color",
    "group": "Colors"
}
*/
$success-color: #4CAF50;
/*
{
    "var": "@error-color",
    "name": "Error",
    "type": "color",
    "group": "Colors"
}
*/
$error-color: #F44336;
/*
{
    "var": "@link-color",
    "name": "Link",
    "type": "color",
    "group": "Colors"
}
*/
$link-color: #039be5;


/* 2. Badges
   ========================================================================== */

/*
{
   "var": "@badge-bg-color",
   "name": "Background",
   "type": "color",
   "group": "Badges"
}
*/
$badge-bg-color: $secondary-color !default;


/* 3. Buttons
   ========================================================================== */

// Shared styles
$button-border: none !default;
$button-background-focus: lighten($secondary-color, 4%) !default;
/*
{
    "var"  : "@button-font-size",
    "name" : "Button Icon Size",
    "type" : "number",
    "units": "rem",
        "range": {
        "min": 0,
        "max": 5,
        "increment": 0.1
    },
    "group": "Buttons"
}
*/
$button-font-size: 1.3rem !default;
/*
{
    "var"  : "@button-height",
    "name" : "Height",
    "type" : "number",
    "units": "px",
        "range": {
        "min": 0,
        "max": 100,
        "increment": 2
    },
    "group": "Buttons"
}
*/
$button-height: 36px !default;
$button-padding: 0 2rem !default;
/*
{
    "var"  : "@button-radius",
    "name" : "Radius",
    "type" : "number",
    "units": "px",
        "range": {
        "min": 0,
        "max": 100,
        "increment": 2
    },
    "group": "Buttons"
}
*/
$button-radius: 2px !default;

// Disabled styles
/*
{
    "var"  : "@button-disabled-background",
    "name" : "Disabled Background",
    "type" : "color",
    "group": "Buttons"
}
*/
$button-disabled-background: #DFDFDF !default;
/*
{
    "var"  : "@button-disabled-color",
    "name" : "Disabled Text",
    "type" : "color",
    "group": "Buttons"
}
*/
$button-disabled-color: #9F9F9F !default;

// Raised buttons
/*
{
    "var"  : "@button-raised-background",
    "name" : "Raised Background",
    "type" : "color",
    "group": "Buttons"
}
*/
$button-raised-background: $secondary-color !default;
/*
{
    "var"  : "@button-raised-background-hover",
    "name" : "Raise Hover",
    "type" : "color",
    "group": "Buttons"
}
*/
$button-raised-background-hover: lighten($button-raised-background, 5%) !default;
/*
{
    "var"  : "@button-raised-color",
    "name" : "Raised Text",
    "type" : "color",
    "group": "Buttons"
}
*/
$button-raised-color: #fff !default;

// Large buttons
/*
{
    "var"  : "@button-large-icon-font-size",
    "name" : "Large Button Icon Font Size",
    "type" : "number",
    "units": "rem",
        "range": {
        "min": 0,
        "max": 10,
        "increment": 0.2
    },
    "group": "Buttons"
}
*/
$button-large-icon-font-size: 1.6rem !default;
/*
{
    "var"  : "@button-large-height",
    "name" : "Large Button Height",
    "type" : "number",
    "units": "px",
        "range": {
        "min": 0,
        "max": 100,
        "increment": 2
    },
    "group": "Buttons"
}
*/
$button-large-height: $button-height * 1.5 !default;

// Flat buttons
/*
{
    "var"  : "@button-flat-color",
    "name" : "Flat Button Text",
    "type" : "color",
    "group": "Buttons"
}
*/
$button-flat-color: #343434 !default;
$button-flat-disabled-color: lighten(#999, 10%) !default;

// Floating buttons
/*
{
    "var"  : "@button-floating-background",
    "name" : "Floating Button Background",
    "type" : "color",
    "group": "Buttons"
}
*/
$button-floating-background: $secondary-color !default;
/*
{
    "var"  : "@button-floating-background-hover",
    "name" : "Floating Button Hover",
    "type" : "color",
    "group": "Buttons"
}
*/
$button-floating-background-hover: $button-floating-background !default;
/*
{
    "var"  : "@button-floating-color",
    "name" : "Floating Button Text",
    "type" : "color",
    "group": "Buttons"
}
*/
$button-floating-color: #fff !default;
/*
{
    "var"  : "@button-floating-size",
    "name" : "Floating Button Size",
    "type" : "number",
    "units": "px",
        "range": {
        "min": 0,
        "max": 100,
        "increment": 1
    },
    "group": "Buttons"
}
*/
$button-floating-size: 37px !default;
/*
{
    "var"  : "@button-floating-large-size",
    "name" : "Large Floating Button Size",
    "type" : "number",
    "units": "px",
        "range": {
        "min": 0,
        "max": 100,
        "increment": 1
    },
    "group": "Buttons"
}
*/
$button-floating-large-size: $button-floating-size * 1.5 !default;
/*
{
    "var"  : "@button-floating-radius",
    "name" : "Floating Button Radius",
    "type" : "number",
    "units": "%",
        "range": {
        "min": 0,
        "max": 100,
        "increment": 1
    },
    "group": "Buttons"
}
*/
$button-floating-radius: 50% !default;


/* 4. Cards
   ========================================================================== */
/*
{
    "var"  : "@card-padding",
    "name" : "Padding",
    "type" : "number",
    "units": "px",
        "range": {
        "min": 0,
        "max": 100,
        "increment": 1
    },
    "group": "Cards"
}
*/
$card-padding: 20px !default;
/*
{
    "var"  : "@card-bg-color",
    "name" : "Card Background Color",
    "type" : "color",
    "group": "Cards"
}
*/
$card-bg-color: #fff !default;
/*
{
    "var"  : "@card-link-color",
    "name" : "Card Link Color",
    "type" : "color",
    "group": "Cards"
}
*/
$card-link-color: #ffab40;
$card-link-color-light: lighten($card-link-color, 20%) !default;


/* 5. Collapsible
   ========================================================================== */

/*
{
    "var"  : "@collapsible-height",
    "name" : "Collapsible Height",
    "type" : "number",
    "units": "rem",
        "range": {
        "min": 0,
        "max": 10,
        "increment": 1
    },
    "group": "Collapsible"
}
*/
$collapsible-height: 3rem !default;
/*
{
    "var"  : "@collapsible-header-color",
    "name" : "Collapsible Header",
    "type" : "color",
    "group": "Collapsible"
}
*/
$collapsible-header-color: #fff !default;
/*
{
    "var"  : "@collapsible-border-color",
    "name" : "Collapsible Border",
    "type" : "color",
    "group": "Collapsible"
}
*/
$collapsible-border-color: #ddd !default;


/* 6. Chips
   ========================================================================== */
/*
{
  "var" : "@chip-bg-color",
  "name" : "Chip Background Color",
  "type" : "color",
  "group": "Chips"
}
*/
$chip-bg-color: #e4e4e4 !default;
$chip-border-color: #9e9e9e !default;
$chip-selected-color: #26a69a !default;
/*
{
  "var"  : "@chip-margin",
  "name" : "Margin",
  "type" : "number",
  "units": "px",
  "range": {
    "min": 0,
    "max": 25,
    "increment": 1
  },
  "group": "Chips"
}
*/
$chip-margin: 5px !default;


/* 7. Date Picker
   ========================================================================== */


$datepicker-weekday-bg: darken($secondary-color, 7%) !default;
$datepicker-date-bg: $secondary-color !default;
$datepicker-year: rgba(255, 255, 255, .4) !default;
$datepicker-focus: rgba(0,0,0, .05) !default;
$datepicker-selected: $secondary-color !default;
$datepicker-selected-outfocus: desaturate(lighten($secondary-color, 35%), 15%) !default;


/* 8. Dropdown
   ========================================================================== */

/*
{
 "var" : "@dropdown-bg-color",
 "name" : "Dropdown Background Color",
 "type" : "color",
 "group": "Dropdown"
}
*/
$dropdown-bg-color: #fff !default;
/*
{
  "var" : "@dropdown-hover-bg-color",
  "name" : "Dropdown Hover Background Color",
  "type" : "color",
  "group": "Dropdown"
}
*/
$dropdown-hover-bg-color: #eee !default;
/*
{
  "var" : "@dropdown-color",
  "name" : "Dropdown Text Color",
  "type" : "color",
  "group": "Dropdown"
}
*/
$dropdown-color: $secondary-color !default;
$dropdown-item-height: 50px !default;


/* 9. Fonts
   ========================================================================== */

$roboto-font-path: "../lib/materialize/fonts/roboto/" !default;


/* 10. Forms
   ========================================================================== */

// Text Inputs + Textarea
/*
{
  "var"  : "@input-height",
  "name" : "Input Height",
  "type" : "number",
  "units": "rem",
  "range": {
    "min": 1,
    "max": 5,
    "increment": 1
  },
  "group": "Forms"
}
*/
$input-height: 3rem !default;
/*
{
  "var" : "@input-border-color",
  "name" : "Input Border Color",
  "type" : "color",
  "group": "Forms"
}
*/
$input-border-color: #9e9e9e;
$input-border: 1px solid $input-border-color !default;
/*
{
  "var" : "@input-background",
  "name" : "Input Background Color",
  "type" : "color",
  "group": "Forms"
}
*/
$input-background: #fff !default;
/*
{
  "var" : "@input-error-color",
  "name" : "Input Error Color",
  "type" : "color",
  "group": "Forms"
}
*/
$input-error-color: $error-color !default;
/*
{
  "var" : "@input-success-color",
  "name" : "Input Success Color",
  "type" : "color",
  "group": "Forms"
}
*/
$input-success-color: $success-color !default;
/*
{
  "var" : "@input-focus-color",
  "name" : "Input Focus Color",
  "type" : "color",
  "group": "Forms"
}
*/
$input-focus-color: $secondary-color !default;
/*
{
  "var"  : "@input-font-size",
  "name" : "Input Font Size",
  "type" : "number",
  "units": "rem",
  "range": {
    "min": 1,
    "max": 5,
    "increment": 0.1
  },
  "group": "Forms"
}
*/
$input-font-size: 1rem !default;
$input-margin: 0 0 20px 0 !default;
$input-padding: 0 !default;
$input-transition: all .3s !default;
/*
{
  "var"  : "@label-font-size",
  "name" : "Label Font Size",
  "type" : "number",
  "units": "rem",
  "range": {
    "min": 0,
    "max": 3,
    "increment": 0.1
  },
  "group": "Forms"
}
*/
$label-font-size: .8rem !default;
/*
{
  "var" : "@input-disabled-color",
  "name" : "Input Disabled Color",
  "type" : "color",
  "group": "Forms"
}
*/
$input-disabled-color: rgba(0,0,0, .26) !default;
$input-disabled-solid-color: #BDBDBD !default;
$input-disabled-border: 1px dotted $input-disabled-color !default;
$input-invalid-border: 1px solid $input-error-color !default;
/*
{
  "var" : "@placeholder-text-color",
  "name" : "Placeholder Text Color",
  "type" : "color",
  "group": "Forms"
}
*/
$placeholder-text-color: lighten($input-border-color, 20%) !default;

// Radio Buttons
/*
{
  "var" : "@radio-fill-color",
  "name" : "Radio Button & Checkbox Filled Color",
  "type" : "color",
  "group": "Forms"
}
*/
$radio-fill-color: $secondary-color !default;
/*
{
  "var" : "@radio-empty-color",
  "name" : "Radio Button & Checkbox Empty Color",
  "type" : "color",
  "group": "Forms"
}
*/
$radio-empty-color: #5a5a5a !default;
$radio-border: 2px solid $radio-fill-color !default;

// Range
/*
{
  "var"  : "@range-height",
  "name" : "Range Height",
  "type" : "number",
  "units": "px",
  "range": {
    "min": 1,
    "max": 30,
    "increment": 1
  },
  "group": "Forms"
}
*/
$range-height: 14px !default;
/*
{
  "var"  : "@range-width",
  "name" : "Range Width",
  "type" : "number",
  "units": "px",
  "range": {
    "min": 1,
    "max": 30,
    "increment": 1
  },
  "group": "Forms"
}
*/
$range-width: 14px !default;
/*
{
  "var"  : "@track-height",
  "name" : "Track Height",
  "type" : "number",
  "units": "px",
  "range": {
    "min": 1,
    "max": 30,
    "increment": 1
  },
  "group": "Forms"
}
*/
$track-height: 3px !default;

// Select
$select-border: 1px solid #f2f2f2 !default;
$select-background: rgba(255, 255, 255, 0.90) !default;
$select-focus: 1px solid lighten($secondary-color, 47%) !default;
$select-padding: 5px !default;
$select-radius: 2px !default;
$select-disabled-color: rgba(0,0,0,.3) !default;

// Switches
$switch-bg-color: $secondary-color !default;
$switch-checked-lever-bg: desaturate(lighten($secondary-color, 25%), 25%) !default;
$switch-unchecked-bg: #F1F1F1 !default;
$switch-unchecked-lever-bg: #818181 !default;
$switch-radius: 15px !default;


/* 11. Global
   ========================================================================== */

// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;


/* 12. Grid
   ========================================================================== */

$num-cols: 12 !default;
$gutter-width: 1.5rem !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width*2)/3 !default;


/* 13. Navigation Bar
   ========================================================================== */

/*
{
 "var"  : "@navbar-height",
 "name" : "Navbar Height",
 "type" : "number",
 "units": "px",
 "range": {
   "min": 1,
   "max": 100,
   "increment": 1
 },
 "group": "Navigation Bar"
}
*/
$navbar-height: 64px !default;
/*
{
  "var"  : "@navbar-height-mobile",
  "name" : "Navbar Height Mobile",
  "type" : "number",
  "units": "px",
  "range": {
    "min": 1,
    "max": 100,
    "increment": 1
  },
  "group": "Navigation Bar"
}
*/
$navbar-height-mobile: 56px !default;
/*
{
  "var"  : "@navbar-font-size",
  "name" : "Navbar Font Size",
  "type" : "number",
  "units": "rem",
  "range": {
    "min": 1,
    "max": 5,
    "increment": 0.1
  },
  "group": "Navigation Bar"
}
*/
$navbar-font-size: 1rem !default;
/*
{
  "var" : "@navbar-font-color",
  "name" : "Navbar Font Color",
  "type" : "color",
  "group": "Navigation Bar"
}
*/
$navbar-font-color: #fff !default;
/*
{
  "var"  : "@navbar-brand-font-size",
  "name" : "Navbar Brand Font Size",
  "type" : "number",
  "units": "rem",
  "range": {
    "min": 1,
    "max": 5,
    "increment": 0.1
  },
  "group": "Navigation Bar"
}
*/
$navbar-brand-font-size: 2.1rem !default;

/* 14. Side Navigation
   ========================================================================== */

   /*
   {
     "var"  : "@sidenav-width",
     "name" : "Sidenav Width",
     "type" : "number",
     "units": "px",
     "range": {
       "min": 100,
       "max": 500,
       "increment": 10
     },
     "group": "Side Navigation"
   }
   */
$sidenav-width: 300px !default;
$sidenav-font-size: 14px !default;
/*
{
  "var" : "@sidenav-font-color",
  "name" : "Sidenav Font Color",
  "type" : "color",
  "group": "Side Navigation"
}
*/
$sidenav-font-color: rgba(0,0,0,.87) !default;
/*
{
  "var" : "@sidenav-bg-color",
  "name" : "Sidenav Background Color",
  "type" : "color",
  "group": "Side Navigation"
}
*/
$sidenav-bg-color: #fff !default;
/*
{
  "var"  : "@sidenav-padding",
  "name" : "Navbar Padding",
  "type" : "number",
  "units": "px",
  "range": {
    "min": 1,
    "max": 30,
    "increment": 1
  },
  "group": "Navigation Bar"
}
*/
$sidenav-padding: 16px !default;
/*
{
  "var"  : "@sidenav-item-height",
  "name" : "Navbar Item Height",
  "type" : "number",
  "units": "px",
  "range": {
    "min": 1,
    "max": 100,
    "increment": 1
  },
  "group": "Navigation Bar"
}
*/
$sidenav-item-height: 48px !default;


/* 15. Photo Slider
   ========================================================================== */
/*
{
  "var" : "@slider-bg-color",
  "name" : "Slider Background Color",
  "type" : "color",
  "group": "Photo Slider"
}
*/
$slider-bg-color: #9e9e9e;
/*
{
  "var" : "@slider-bg-color-light",
  "name" : "Slider Background Light Color",
  "type" : "color",
  "group": "Photo Slider"
}
*/
$slider-bg-color-light: #e0e0e0;
/*
{
  "var" : "@slider-indicator-color",
  "name" : "Slider Indicator Color",
  "type" : "color",
  "group": "Photo Slider"
}
*/
$slider-indicator-color: #4CAF50;


/* 16. Spinners | Loaders
   ========================================================================== */

$spinner-default-color: $secondary-color !default;


/* 17. Tabs
   ========================================================================== */

/*
{
"var" : "@tabs-underline-color",
"name" : "Tabs Underline Color",
"type" : "color",
"group": "Tabs"
}
*/
$tabs-underline-color: $primary-color-light !default;
/*
{
 "var" : "@tabs-text-color",
 "name" : "Tabs Text Color",
 "type" : "color",
 "group": "Tabs"
}
*/
$tabs-text-color: $primary-color !default;
/*
{
 "var" : "@tabs-bg-color",
 "name" : "Tabs Background Color",
 "type" : "color",
 "group": "Tabs"
}
*/
$tabs-bg-color: #fff !default;


/* 18. Tables
   ========================================================================== */

/*
{
"var" : "@table-border-color",
"name" : "Table Border Color",
"type" : "color",
"group": "Tables"
}
*/
$table-border-color: #d0d0d0 !default;
/*
{
 "var" : "@table-striped-color",
 "name" : "Table Striped & Hover Color",
 "type" : "color",
 "group": "Tables"
}
*/
$table-striped-color: #f2f2f2 !default;


/* 19. Toasts
   ========================================================================== */

/*
{
 "var"  : "@toast-height",
 "name" : "Toast Height",
 "type" : "number",
 "units": "px",
 "range": {
   "min": 1,
   "max": 100,
   "increment": 1
 },
 "group": "Toasts"
}
*/
$toast-height: 48px !default;
/*
{
"var" : "@toast-color",
"name" : "Toast Color",
"type" : "color",
"group": "Toasts"
}
*/
$toast-color: #323232 !default;
/*
{
"var" : "@toast-text-color",
"name" : "Toast Text Color",
"type" : "color",
"group": "Toasts"
}
*/
$toast-text-color: #fff !default;


/* 20. Typography
   ========================================================================== */

$off-black: rgba(0, 0, 0, 0.87) !default;
// Header Styles
$h1-fontsize: 4.2rem !default;
$h2-fontsize: 3.56rem !default;
$h3-fontsize: 2.92rem !default;
$h4-fontsize: 2.28rem !default;
$h5-fontsize: 1.64rem !default;
$h6-fontsize: 1rem !default;


/* 21. Footer
   ========================================================================== */

/*
{
"var" : "@footer-bg-color",
"name" : "Footer Background Color",
"type" : "color",
"group": "Footer"
}
*/
$footer-bg-color: $primary-color !default;


/* 22. Flow Text
   ========================================================================== */

$range : $large-screen - $small-screen !default;
$intervals: 20 !default;
$interval-size: $range / $intervals !default;


/* 23. Collections
   ========================================================================== */

/*
{
"var" : "@collection-border-color",
"name" : "Collection Border Color",
"type" : "color",
"group": "Collections"
}
*/
$collection-border-color: #e0e0e0 !default;
/*
{
"var" : "@collection-bg-color",
"name" : "Collection Background Color",
"type" : "color",
"group": "Collections"
}
*/
$collection-bg-color: #fff !default;
$collection-active-bg-color: $secondary-color !default;
$collection-active-color: lighten($secondary-color, 55%) !default;
$collection-hover-bg-color: #ddd !default;
/*
{
"var" : "@collection-link-color",
"name" : "Collection Link Color",
"type" : "color",
"group": "Collections"
}
*/
$collection-link-color: $secondary-color !default;


/* 24. Progress Bar
   ========================================================================== */

$progress-bar-color: $secondary-color !default;

/* 25. Interactive Reports
   ========================================================================== */
/*
{
"var" : "@ir-specific-color",
"name" : "Interactive Report Button Color",
"type" : "color",
"group": "Interactive Reports"
}
*/
$ir-specific-color: #9e9e9e;
$ir-specific-color-light: lighten($ir-specific-color, 15%) !default;


/* 26. Modals
   ========================================================================== */

/*
   {
   "var": "@modal-background-color",
   "name": "Modal Background",
   "type": "color",
   "group": "Modals"
}
*/
$modal-background-color: #fafafa;
/*
{
   "var": "@modal-background-header",
   "name": "Modal Header Background",
   "type": "color",
   "group": "Modals"
}
*/
$modal-background-header: $modal-background-color;
/*
{
   "var": "@modal-background-footer",
   "name": "Modal Footer Background",
   "type": "color",
   "group": "Modals"
}
*/
$modal-background-footer: $modal-background-color;
